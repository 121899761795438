<template>
  <div class="container">
    <div class="header" @click="navTo('/me/profile')">
      <div class="avatar">
        <van-image :src="loginUser.avatar ? loginUser.avatar : logo" />
      </div>
      <div class="profile">
        <h3>{{ loginUser.showName ? loginUser.showName : "Hi,同学" }}</h3>
        <p>
          {{
            loginUser.orgFullPathName
              ? "班级：" + loginUser.orgFullPathName
              : "欢迎您"
          }}
        </p>
        <p>
          {{
            loginUser.orgMemo
              ? "QQ群：" + loginUser.orgMemo
              : "留住中国魂 做好国际人"
          }}
        </p>
      </div>
    </div>
    <van-divider
      :style="{
        borderColor: '#ee0a24',
        padding: '0 100px',
      }"
    >
      自助服务
    </van-divider>
    <van-grid clickable :column-num="3" :border="false">
      <van-grid-item
        to="/checkin/greenFee"
        icon="after-sale"
        text="绿色缴费通道"
      />
      <van-grid-item to="/checkin/delay" icon="clock-o" text="延迟报到" />
      <van-grid-item to="/checkin/transfer" icon="exchange" text="转专业申请" />
    </van-grid>
    <van-cell-group style="margin-top: 10px">
      <van-cell title="个人资料" icon="user-o" is-link to="/me/profile" />
      <van-cell title="来校登记" icon="sign" is-link to="/checkin/visit" />
      <van-cell
        v-if="loginUser && loginUser.id && loginUser.isCanFullTimeReq"
        title="申请在校住宿就读"
        icon="aim"
        is-link
        to="/checkin/fullday"
      />
    </van-cell-group>
    <van-cell-group style="margin-top: 10px">
      <van-cell title="我的学费" icon="after-sale" is-link to="/checkin/fee" />
      <van-cell title="我的宿舍" icon="shop-o" is-link to="/checkin/hostel" />
    </van-cell-group>
    <van-cell-group style="margin-top: 10px">
      <van-cell
        v-if="loginUser.id"
        title="退出登录"
        icon="revoke"
        is-link
        @click="logout"
      />
    </van-cell-group>
  </div>
</template>
<script>
import * as api from "@/apis/api";
import { Dialog } from "vant";
export default {
  data() {
    return {
      logo: require("@/assets/logo.png"),
    };
  },
  computed: {
    loginUser() {
      return this.$store.getters.user;
    },
  },
  created() {
    if (this.loginUser.id) {
      api.userProfile().then((res) => {
        this.$store.commit("setUser", res);
      });
    }
  },
  methods: {
    navTo(url) {
      this.$router.push(url);
    },
    logout() {
      Dialog.confirm({
        title: "提示",
        message: "您确认退出登录吗？",
      })
        .then(() => {
          this.$store.commit("logout");
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  background-color: #c12b35;
  padding: 10px;
  color: #fff;
}
.header .avatar {
  flex: 1;
}
.header .profile {
  flex: 3;
  margin-left: 10px;
}
.header .profile h3 {
  margin: 10px 0;
}
.header .profile p {
  margin: 5px 0;
  font-size: 14px;
}
.van-cell__left-icon {
  margin-right: 10px;
}
</style>
